import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Button, Divider, Input } from "antd";
import "../App.css";
import Multumim from "./Multumim";

const { TextArea } = Input;

class Checkout extends Component {
  state = {
    basket: null,
    ids: null,
    fileName: null,
    thank_you: false
  };

  componentDidMount = () => {
    this.setState({
      basket: JSON.parse(localStorage.getItem("vr-basket")),
      ids: JSON.parse(localStorage.getItem("vr-basketIds")),
    });
  };

  removeFromBasket = (index) => {
    let list = this.state.basket;
    let temp = this.state.ids;
    list.splice(index, 1);
    temp.splice(index, 1);
    if (list.length < 1) {
      localStorage.clear();
      this.setState({ basket: null });
    } else {
      this.setState({ basket: list, ids: temp });
      localStorage.setItem("vr-basket", JSON.stringify(list));
      localStorage.setItem("vr-basketIds", JSON.stringify(temp));
    }
  };

  updateComment = (e, index) => {
    let list = JSON.parse(localStorage.getItem("vr-basket"));
    list[index].comment = e.target.value;
    localStorage.setItem("vr-basket", JSON.stringify(list));
    this.setState({basket: list})
  };

  addQuantity = (index) => {
    let list = this.state.basket;
    list[index].quantity = list[index].quantity + 1;
    localStorage.setItem("vr-basket", JSON.stringify(list));
    this.setState({ basket: list });
  };

  subQuantity = (index, quantity) => {
    if (quantity === 1) {
      this.removeFromBasket(index);
    } else {
      let list = this.state.basket;
      list[index].quantity = list[index].quantity - 1;
      localStorage.setItem("vr-basket", JSON.stringify(list));
      this.setState({ basket: list });
    }
  };

  onCommand = () => {
    var all_ok = true;
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const comments = document.getElementById("comments").value;
    var hasFile = false;

    const id = Date.now();

    if (name.length < 3) {
      alert("Numele nu este valid.");
      all_ok = false;
      return;
    }
    if (email.length < 10 || !email.includes("@") || !email.includes(".")) {
      alert("Email-ul nu este valid.");
      all_ok = false;
      return;
    }
    if (phone.length < 9) {
      alert("Numarul de telefon nu este valid.");
      all_ok = false;
      return;
    }

    if (all_ok) {
      var email_text =
        "Nume: " +
        name +
        "<br/>Telefon: " +
        phone +
        "<br/>Email: " +
        email +
        "<br/>Comentarii: " +
        comments +
        "<br/>Comanda: <br/><br/>";

      for (let i = 0; i < this.state.basket.length; i++) {
        email_text +=
          i + 1 + ". " +
          this.state.basket[i].name + ": " + this.state.basket[i].nr +
          "<br/>Cantitate: " +
          this.state.basket[i].quantity +
          "<br/>Personalizarea: " +
          this.state.basket[i].comment +
          "<br/>" +
          this.state.basket[i].img +
          "<br/><br/>";
      }

      if (this.state.fileName) {
        let file = document.getElementById("files").files[0];
        hasFile = true;
        let ref = this.props.storage.ref();
        var uploadTask = ref.child("images/" + id).put(file);
        uploadTask.on("state_changed", () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            email_text += "<br/>File: " + downloadURL + "<br/>";
            this.sendEmail(email_text);
          });
        });
      } else {
        this.sendEmail(email_text);
      }

      this.props.database
        .ref(id)
        .set({
          numele: name,
          email: email,
          telefon: phone,
          comentarii: comments,
          timestamp: id,
          basket: this.state.basket,
          file: hasFile,
        })
        .then(() => {
          localStorage.clear();
          this.setState({ basket: null, thank_you:true });

        });
    }
  };

  sendEmail = (text) => {
    this.props.firestore.collection("mail").add({
      to: "marketing@totalgravura.md, elviratgdecor@gmail.com",
      message: {
        subject: "Comanda noua vreau.md",
        text: text,
        html: text,
      },
    });
  };

  getTotal() {
    var total = 0;
    for (let i = 0; i<this.state.basket.length; i++ ){
      total += this.state.basket[i].quantity * this.state.basket[i].price
    }
    return total
  }

  checkFile = () => {
    let fileName = document.getElementById("files").files[0].name;
    if (fileName) {
      this.setState({ fileName });
    }
  };
  render() {
    if(this.state.thank_you){
      return(
        <Multumim />
      )
    }else{
      return (
        <div>
          <Header />
          {this.state.basket != null ? (
            <div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "hidden",
                  justifyContent: "center",
                }}
              >
                {JSON.parse(localStorage.getItem("vr-basket")).map(
                  (item, index) => (
                    <div style={{ width: "280px", margin: "20px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ lineHeight: "40px" }}>
                          {item.name} nr {item.nr}
                        </h4>
                        <Button
                          onClick={() => this.removeFromBasket(index)}
                          style={{ float: "right", margin: "5px 0px" }}
                          type="danger"
                        >
                          X
                        </Button>
                      </div>
                      <img
                        src={item.img}
                        alt={item.name}
                        style={{ width: "100%" }}
                      ></img>
                      <i>Personalizare: </i>{" "}
                      <TextArea
                        value={item.comment}
                        rows={3}
                        placeholder="Detalii de presonalizare. Exemplu: nume, prenume, ziua de naștere, etc."
                        onChange={(e) => this.updateComment(e, index)}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px 0px",
                        }}
                      >
                        <p style={{ lineHeight: "34px" }}>Cantitatea:</p>{" "}
                        <Button
                          onClick={() => this.subQuantity(index, item.quantity)}
                        >
                          -
                        </Button>
                        <h4 style={{ lineHeight: "34px" }}>{item.quantity}</h4>
                        <Button onClick={() => this.addQuantity(index)}>
                          +
                        </Button>
                      </div>
                      <b
                        style={{
                          lineHeight: "14px",
                          marginTop: "10px",
                          color: "rgb(131,27,41)",
                        }}
                      >
                        Preț: {item.price} Lei &times; {item.quantity} ={" "}
                        {item.price * item.quantity} Lei
                      </b>{" "}
                      <Divider />
                    </div>
                  )
                )}
              </div>
  
              <Input
                id="name"
                placeholder="Nume și Prenume"
                className="checkout-input"
              ></Input>
              <Input
                id="phone"
                placeholder="Număr de contact"
                className="checkout-input"
              ></Input>
              <Input
                id="email"
                placeholder="Email"
                className="checkout-input"
              ></Input>
              <TextArea
                id="comments"
                rows={4}
                placeholder="Comentarii"
                className="checkout-input"
              ></TextArea>
  
              <Button
                className="checkout-input"
                onClick={() => document.getElementById("files").click()}
              >
                Adaugă file...
              </Button>
              {this.state.fileName && (
                <p className="checkout-input">{this.state.fileName}</p>
              )}
              <input
                className="checkout-input"
                id="files"
                type="file"
                style={{ visibility: "hidden" }}
                onChange={() => this.checkFile()}
                tag="alege"
              ></input>
              <p  className="checkout-input">* Comanda se achită doar la primirea produsului în numerar sau cu cardul bancar la noi în oficiu.</p>
              <h2 style={{color: "rgb(131,27,41)",}}>Total: {this.getTotal()} Lei</h2>
  
              <Button
                className="checkout-input"
                type="primary"
                onClick={() => this.onCommand()}
              >
                Trimite Comanda
              </Button>
            </div>
          ) : (
            <h1 style={{ textAlign: "center", margin: "30vh 0px" }}>
              Coșul este gol. <a href="/"> Alege produsul dorit.</a>
            </h1>
          )}
          <Footer />
        </div>
      );
    }
    
  }
}
export default Checkout;
