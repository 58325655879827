import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Row, Col, Image, Button, Drawer, Divider, Input } from "antd";
import Modal from "antd/lib/modal/Modal";


const { TextArea } = Input;

class CategoryPage extends Component {
  state = {

    visible: false,
    commentModal: false,
    selected: null,
  };

  

  addComment = (item, nr) => {
    this.setState({
      selected: {
        img: item.img,
        price: item.price,
        index: nr,
        comment: "",
        name: this.props.data.name,
      },
      commentModal: true,
    });
  };

  commentChange = (e) => {
    var temp = this.state.selected;
    temp.comment = e.target.value;
    this.setState({ selected: temp });
  };

  onModalClose = () => {
    this.setState({ commentModal: false, selected: null });
  };

  add = () => {
    var basketItem = {
      name: this.props.data.name,
      img: this.state.selected.img,
      nr: this.state.selected.index,
      price: this.state.selected.price,
      comment: this.state.selected.comment,
      quantity: 1,
    };
    var tempId = "" + this.props.id + "" + basketItem.nr;

    if (localStorage.getItem("vr-basketIds") != null) {
      let temp = JSON.parse(localStorage.getItem("vr-basketIds"));
      let list = JSON.parse(localStorage.getItem("vr-basket"));
      if (temp.includes(tempId)) {
        list[temp.indexOf(tempId)].quantity =
          list[temp.indexOf(tempId)].quantity + 1;
        localStorage.setItem("vr-basket", JSON.stringify(list));
      } else {
        temp.push(tempId);
        list.push(basketItem);
        localStorage.setItem("vr-basketIds", JSON.stringify(temp));
        localStorage.setItem("vr-basket", JSON.stringify(list));
      }
    } else {
      let temp = [];
      let list = [];
      temp.push(tempId);
      list.push(basketItem);
      localStorage.setItem("vr-basketIds", JSON.stringify(temp));
      localStorage.setItem("vr-basket", JSON.stringify(list));
    }
    this.setState({ visible: true, commentModal: false, selected: null });
  };
  onClose = () => {
    this.setState({ visible: false });
  };
  showDrawer = () => {
    this.setState({ visible: true });
  };

  clearBasket = () => {
    this.setState({ visible: false });
    localStorage.clear();
  };

  removeFromBasket = (index) => {
    let list = JSON.parse(localStorage.getItem("vr-basket"));
    let temp = JSON.parse(localStorage.getItem("vr-basketIds"));
    list.splice(index, 1);
    temp.splice(index, 1);
    if (list.length < 1) {
      localStorage.clear();
    } else {
      localStorage.setItem("vr-basket", JSON.stringify(list));
      localStorage.setItem("vr-basketIds", JSON.stringify(temp));
    }
    this.onClose();
    this.showDrawer();
  };

  updateComment = (e, index) => {
    let list = JSON.parse(localStorage.getItem("vr-basket"));
    list[index].comment = e.target.value;
    localStorage.setItem("vr-basket", JSON.stringify(list));
    this.onClose();
    this.showDrawer();
  };
  addQuantity = (index) => {
    let list = JSON.parse(localStorage.getItem("vr-basket"));
    list[index].quantity = list[index].quantity + 1;
    localStorage.setItem("vr-basket", JSON.stringify(list));
    this.onClose();
    this.showDrawer();
  };

  subQuantity = (index, quantity) => {
    if (quantity === 1) {
      this.removeFromBasket(index);
    } else {
      let list = JSON.parse(localStorage.getItem("vr-basket"));
      list[index].quantity = list[index].quantity - 1;
      localStorage.setItem("vr-basket", JSON.stringify(list));
    }
    this.onClose();
    this.showDrawer();
  };

  
  render() {
    const data = this.props.data;

    return (
      <div>
        <Header visible={this.state.visible} />
        <h1 className="h1">{data.name}</h1>
        <p className="description">{data.description}</p>
        <Row>
          {data.products.map((item, index) => (
            <>
           {item.price &&  <Col span={12} key={index}>
              <Image
                alt={data.name}
                className="photo"
                id="myimg"
                src={item.img}
              />
              <h3 style={{ textAlign: "center", marginTop: "20px" }}>
                {data.name} - {index + 1}{" "}
              </h3>
              <p style={{ textAlign: "center", color: "#888", margin:'5px 40px' }}>{item.info}</p>
              <h2 style={{ color: "rgb(131,27,41)" }}>
                Preț: {item.price} Lei / bucată
              </h2>
              <Button
                type="primary"
                className="add-button"
                onClick={() => this.addComment(item, index + 1)}
              >
                Adaugă în coș
              </Button>
            </Col>}
            </>
          ))}
        </Row>

        <Modal
          visible={this.state.commentModal}
          title="Personalizează"
          closable={false}
          footer={[
            <Button key="back" onClick={() => this.onModalClose()}>
              Înapoi
            </Button>,
            <Button key="submit" type="primary" onClick={() => this.add()}>
              Adaugă
            </Button>,
          ]}
        >
          {this.state.selected && (
            <div>
              <img
                alt="Cadou 14 februarie"
                className="photo"
                id="myimg"
                src={this.state.selected.img}
              />
              <h3 style={{ textAlign: "center", marginTop: "20px" }}>
                {this.state.selected.name} - {this.state.selected.index}
              </h3>
              <h2 style={{ color: "rgb(131,27,41)" }}>
                Preț: {this.state.selected.price}
              </h2>
              <TextArea
                id="productComment"
                placeholder="Detalii de presonalizare. Exemplu: nume, prenume, ziua de naștere, etc."
                onChange={(e) => this.commentChange(e)}
                value={this.state.selected.comment}
                autoFocus
                rows={3}
              />
            </div>
          )}
        </Modal>

        <Drawer
          title="Coș"
          placement="right"
          id="cos"
          closable={true}
          onClose={() => this.onClose()}
          visible={this.state.visible}
          width="276"
          bodyStyle={{ overflowX: "hidden" }}
        >
          {localStorage.getItem("vr-basket") != null ? (
            <div>
              <p>
                * Comanda se achită doar la primirea produsului în numerar sau
                cu cardul bancar.
              </p>
              {JSON.parse(localStorage.getItem("vr-basket")).map(
                (item, index) => (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 style={{ lineHeight: "40px" }}>
                        {item.name} nr {item.nr}
                      </h4>
                      <Button
                        onClick={() => this.removeFromBasket(index)}
                        style={{ float: "right", margin: "5px 0px" }}
                        type="danger"
                      >
                        X
                      </Button>
                    </div>
                    <img
                      src={item.img}
                      alt={item.name}
                      style={{ width: "100%" }}
                    ></img>
                    <i>Personalizare: </i>{" "}
                    <TextArea
                      value={item.comment}
                      rows={3}
                      placeholder="Detalii de presonalizare. Exemplu: nume, prenume, ziua de naștere, etc."
                      onChange={(e) => this.updateComment(e, index)}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "5px 0px",
                      }}
                    >
                      <p style={{ lineHeight: "34px" }}>Cantitatea:</p>{" "}
                      <Button
                        onClick={() => this.subQuantity(index, item.quantity)}
                      >
                        -
                      </Button>
                      <h4 style={{ lineHeight: "34px" }}>{item.quantity}</h4>
                      <Button onClick={() => this.addQuantity(index)}>+</Button>
                    </div>
                    <b
                      style={{
                        lineHeight: "14px",
                        marginTop: "10px",
                        color: "rgb(131,27,41)",
                      }}
                    >
                      Preț: {item.price} Lei &times; {item.quantity} ={" "}
                      {item.price * item.quantity} Lei
                    </b>{" "}
                    <Divider />
                  </div>
                )
              )}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={() => this.clearBasket()} type="danger">
                  Șterge tot
                </Button>
                <Button href="/checkout" type="primary">
                  Continuă
                </Button>
              </div>
            </div>
          ) : (
            <div>Coșul este gol!</div>
          )}
        </Drawer>
        <Footer />
      </div>
    );
  }
}
export default CategoryPage;
