import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import CategoryPage from "./components/CategoryPage";
import Checkout from "./components/Checkout";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";

import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyDYQj301mzAeft_Kg22GxV90h9aBmYlzyk",
  authDomain: "cadouri-corporative.firebaseapp.com",
  databaseURL: "https://cadouri-corporative.firebaseio.com",
  projectId: "cadouri-corporative",
  storageBucket: "cadouri-corporative.appspot.com",
  messagingSenderId: "249782211075",
  appId: "1:249782211075:web:3c8f947108b18383533436",
  measurementId: "G-44GGMR4DJR",
};
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();
var storage = firebase.storage();
var database = firebase.database();

var categories = [];

db.collection("categories")
  .get()
  .then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      // CONSOLE
      if(doc.data().vreau) {
        var temp = {
        name: doc.id,
        data: doc.data(),
      };
      categories.push(temp);
      }
      
    });
  })
  .then(() => {
    ReactDOM.render(
      <Router>
        <Switch>
          {categories.map((product) => (
            <Route key={product.name} path={"/" + product.name}>
              <CategoryPage
                data={product.data}
                id={product.name}
                storage={storage}
              />
            </Route>
          ))}
          <Route exact strict path="/">
            <App categories={categories} />
          </Route>
          <Route  path="/checkout">
            <Checkout database={database} firestore={db} storage={storage}/>
          </Route>
        </Switch>
      </Router>,
      document.getElementById("root")
    );
  });
