import React from "react";
import "./App.css";
import { Row, Col } from "antd";
import Footer from "./components/Footer";
import Header from "./components/Header";

class App extends React.Component {
  state = {
    categories: [],
  };

  componentDidMount = () => {
    // firebase.auth().signInAnonymously().catch(function(error) {
    // });
    this.setState({ categories: this.props.categories });
  };

  onClick = (h) => {
    window.location.assign(h);
  };
  render() {
    const categories = this.state.categories;
    return (
      <div>
        <Header />

        <Row>
          {categories.map((item) => (
            <Col
              key={item.name}
              className="border"
              style={{
                background:
                  "linear-gradient( rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(" +
                  item.data.cover +
                  ")",
                backgroundSize: "cover",
                transition: "0.2s",
                backgroundPosition: "center",
              }}
              onClick={() => this.onClick("/" + item.name)}
            >
              <h1 className="center-text">{item.data.name}</h1>
            </Col>
          ))}
        </Row>
        <Footer />
      </div>
    );
  }
}

export default App;
