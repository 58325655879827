/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import { MailOutlined, PhoneOutlined, PushpinOutlined } from '@ant-design/icons'

import insta from '../img/insta.png'
import fb from '../img/fb.png'



export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
            <h2>Pentru mai multe detalii contactați-ne:</h2>
    
 <div style={{textAlign:'center', margin:'10px auto'}} id='footer'>
                <a
                  className="futerLink"
                  title="Instagram"
                  href="https://www.instagram.com/tg_decormd/"
                  target="_blank"
                >
                  <img
                    className="futerImg"
                    alt="instagram"
                    src={insta}
                  />
                </a>
     <a
                  className="futerLink"
                  href="https://ok.ru/tg.decor"
                  target="_blank"
                  title="Odnoklassniki"
                >
                  <img
                    className="futerImg"
                    alt="educards odnoklassniki"
                    src="https://edu-cards.com/wp-content/uploads/2020/08/Odnoklassniki-Logo.png"
                  />
                </a>
                <a
                  className="futerLink"
                  title="Facebook"
                  href="https://www.facebook.com/tgdecormoldova"
                  target="_blank"
                >
                  <img
                    className="futerImg"
                    alt="educards facebook"
                    src={fb}
                  />
                </a>
                </div>
               

            <div style={{textAlign:'center'}} className='center-link'>
    
                <PhoneOutlined />
                <a href="tel:022265866"> 022 265 866</a>
                <br />
                <div style={{height:'10px'}}></div>
    
                <MailOutlined />
                <a href="mailto:tgdecor@totalgravura.md">  tgdecor@totalgravura.md
                </a>
                <br />
                <div style={{height:'10px'}}></div>


                <PushpinOutlined />
                <a href='https://g.page/total-gravura?share'> Cernăuți 20a, Chișinău MD</a>
                </div>


               
          </div>
        )
    }
}
